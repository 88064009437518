import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/api";

export const ProductPost = createAsyncThunk("Product/post", async (body) => {
  return await axios.post(`${API_URL}/products`, body).then((res) => res);
});

export const ProductsGet = createAsyncThunk("Product/get", async () => {
  return await axios
    .get(`${API_URL}/products`)
    .then((response) => response.data);
});

export const ProductsGetId = createAsyncThunk("Product/getId", async (body) => {
  return await axios
    .get(`${API_URL}/products/${body}`)
    .then((response) => response.data);
});

export const ProductsFilter = createAsyncThunk(
  "ProductFilter/get",
  async ({ categId, subId, brandId, min, max }) => {
    return await axios
      .get(
        `${API_URL}/products?categoryId=${categId}&brandId=${brandId}&subCategoryId=${subId}&price=min&min=${min}&max=${max}`
      )
      .then((response) => response.data);
  }
);

export const ProductDelete = createAsyncThunk("Product/delete", async (id) => {
  return await axios
    .delete(`${API_URL}/products/${id}`)
    .then((response) => response.data);
});
export const ProductPut = createAsyncThunk(
  "Product/put",
  async ({ body, id }) => {
    return await axios
      .put(`${API_URL}/products/${id}`, body)
      .then((response) => console.log(response.data));
  }
);

export const ProductUpload = createAsyncThunk("Product/upload", async (e) => {
  const formData = new FormData();
  formData.append("file", e.target.files[0]);
  formData.append("upload_preset", "vbxugvuq");
  try {
    return await axios
      .post("https://api.cloudinary.com/v1_1/dxv63ahxx/upload", formData)
      .then((response) => response?.data.secure_url);
  } catch (error) {
    return error;
  }
});
export const ProductUpload1 = createAsyncThunk("Product1/upload1", async (e) => {
  const formData = new FormData();
  formData.append("file", e.target.files[0]);
  formData.append("upload_preset", "vbxugvuq");
  try {
    return await axios
      .post("https://api.cloudinary.com/v1_1/dxv63ahxx/upload", formData)
      .then((response) => response?.data.secure_url);
  } catch (error) {
    return error;
  }
});
export const ProductUpload2 = createAsyncThunk("Product2/upload2", async (e) => {
  const formData = new FormData();
  formData.append("file", e.target.files[0]);
  formData.append("upload_preset", "vbxugvuq");
  try {
    return await axios
      .post("https://api.cloudinary.com/v1_1/dxv63ahxx/upload", formData)
      .then((response) => response?.data.secure_url);
  } catch (error) {
    return error;
  }
});

const ProductSlice = createSlice({
  name: "Product",
  initialState: {
    productsGet: {
      loading: false,
      data: [],
      error: false,
      success: false,
    },
    productsGetId: {
      loading: false,
      data: [],
      error: false,
      success: false,
    },
    productsFilter: {
      loading: false,
      data: [],
      error: false,
      success: false,
    },
    productPost: {
      Success: false,
      Error: false,
      loading: false,
    },
    productDelete: {
      Success: false,
      Error: false,
      loading: false,
    },
    productPut: {
      Error: false,
      Loading: false,
      Success: false,
    },
    //upload
    productUpload: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
    productUpload1: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
    productUpload2: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
  },
  extraReducers: {
    // get
    [ProductsGet.pending]: (state, action) => {
      state.productsGet.loading = true;
    },
    [ProductsGet.fulfilled]: (state, action) => {
      state.productsGet.loading = false;
      state.productsGet.success = true;
      state.productsGet.data = action.payload;
      state.productsGet.error = false;
    },
    [ProductsGet.rejected]: (state, action) => {
      state.productsGet.loading = false;
      state.productsGet.error = true;
      state.productsGet.success = false;
    },
    [ProductsGetId.pending]: (state, action) => {
      state.productsGetId.loading = true;
    },
    [ProductsGetId.fulfilled]: (state, action) => {
      state.productsGetId.loading = false;
      state.productsGetId.success = true;
      state.productsGetId.data = action.payload;
      state.productsGetId.error = false;
    },
    [ProductsGetId.rejected]: (state, action) => {
      state.productsGetId.loading = false;
      state.productsGetId.error = true;
      state.productsGetId.success = false;
    },
    [ProductsFilter.pending]: (state, action) => {
      state.productsFilter.loading = true;
    },
    [ProductsFilter.fulfilled]: (state, action) => {
      state.productsFilter.loading = false;
      state.productsFilter.success = true;
      state.productsFilter.data = action.payload;
      state.productsFilter.error = false;
    },
    [ProductsFilter.rejected]: (state, action) => {
      state.productsFilter.loading = false;
      state.productsFilter.error = true;
      state.productsFilter.success = false;
    },
    // add
    [ProductPost.pending]: (state, action) => {
      state.productPost.loading = true;
    },
    [ProductPost.fulfilled]: (state, action) => {
      state.productPost.loading = false;
      state.productPost.Success = true;
      state.productPost.Error = false;
    },
    [ProductPost.rejected]: (state, action) => {
      state.productPost.loading = false;
      state.productPost.Error = true;
      state.productPost.Success = false;
    },
    // delete
    [ProductDelete.pending]: (state, action) => {
      state.productDelete.loadingDelete = true;
    },
    [ProductDelete.fulfilled]: (state, action) => {
      state.productDelete.loading = false;
      state.productDelete.Success = true;
      state.productDelete.Error = false;
    },
    [ProductDelete.rejected]: (state, action) => {
      state.productDelete.loading = false;
      state.productDelete.Error = true;
      state.productDelete.Success = false;
    },
    // put
    [ProductPut.pending]: (state, action) => {
      state.productPut.loading = true;
    },
    [ProductPut.fulfilled]: (state, action) => {
      state.productPut.Error = false;
      state.productPut.Success = true;
      state.productPut.Loading = false;
    },
    [ProductPut.rejected]: (state, action) => {
      state.productPut.Error = true;
      state.productPut.Success = false;
      state.productPut.Loading = false;
    },
    //upload
    [ProductUpload.pending]: (state, action) => {
      state.productUpload.Loading = true;
    },
    [ProductUpload.fulfilled]: (state, action) => {
      state.productUpload.Error = false;
      state.productUpload.Success = true;
      state.productUpload.Loading = false;
      state.productUpload.data = action.payload;
    },
    [ProductUpload.rejected]: (state, action) => {
      state.productUpload.Error = true;
      state.productUpload.Success = false;
      state.productUpload.Loading = false;
    },
    [ProductUpload1.pending]: (state, action) => {
      state.productUpload1.Loading = true;
    },
    [ProductUpload1.fulfilled]: (state, action) => {
      state.productUpload1.Error = false;
      state.productUpload1.Success = true;
      state.productUpload1.Loading = false;
      state.productUpload1.data = action.payload;
    },
    [ProductUpload1.rejected]: (state, action) => {
      state.productUpload1.Error = true;
      state.productUpload1.Success = false;
      state.productUpload1.Loading = false;
    },
    [ProductUpload2.pending]: (state, action) => {
      state.productUpload2.Loading = true;
    },
    [ProductUpload2.fulfilled]: (state, action) => {
      state.productUpload2.Error = false;
      state.productUpload2.Success = true;
      state.productUpload2.Loading = false;
      state.productUpload2.data = action.payload;
    },
    [ProductUpload2.rejected]: (state, action) => {
      state.productUpload2.Error = true;
      state.productUpload2.Success = false;
      state.productUpload2.Loading = false;
    },
  },
});

export const {} = ProductSlice.actions;
export default ProductSlice.reducer;
