import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/api";

export const CategoryPost = createAsyncThunk("Category/post", async (body) => {
  return await axios.post(`${API_URL}/category`, body).then((res) => res);
});
export const SubCategoryPost = createAsyncThunk(
  "category/post",
  async (body) => {
    return await axios.post(`${API_URL}/sub_category`, body).then((res) => res);
  }
);
export const CategoriesGet = createAsyncThunk("Category/get", async () => {
  return await axios
    .get(`${API_URL}/category`)
    .then((response) => response.data);
});
export const SubCategoriesGet = createAsyncThunk(
  "SubCategory/get",
  async () => {
    return await axios
      .get(`${API_URL}/sub_category`)
      .then((response) => response.data);
  }
);
export const CategoryDelete = createAsyncThunk(
  "Category/delete",
  async (id) => {
    return await axios
      .delete(`${API_URL}/category/${id}`)
      .then((response) => response.data);
  }
);
export const SubCategoryDelete = createAsyncThunk(
  "Category/delete",
  async (id) => {
    return await axios
      .delete(`${API_URL}/sub_category/${id}`)
      .then((response) => response.data);
  }
);
export const CategoryPut = createAsyncThunk(
  "Category/put",
  async ({ body, id }) => {
    return await axios
      .put(`${API_URL}/category/${id}`, body)
      .then((response) => console.log(response.data));
  }
);
export const SubCategoryPut = createAsyncThunk(
  "SubCategory/put",
  async ({ body, id }) => {
    return await axios
      .put(`${API_URL}/sub_category/${id}`, body)
      .then((response) => console.log(response.data));
  }
);
export const CategoryUpload = createAsyncThunk("Category/upload", async (e) => {
  const formData = new FormData();
  formData.append("file", e.target.files[0]);
  formData.append("upload_preset", "vbxugvuq");
  try {
    return await axios
      .post("https://api.cloudinary.com/v1_1/dxv63ahxx/upload", formData)
      .then((response) => response?.data.secure_url);
  } catch (error) {
    return error;
  }
});

const Categorylice = createSlice({
  name: "Category",
  initialState: {
    categoriesGet: {
      Loading: false,
      data: [],
      Error: false,
      Success: false,
    },
    subCategoriesGet: {
      Loading: false,
      data: [],
      Error: false,
      Success: false,
    },
    categoryPost: {
      Error: false,
      Loading: false,
      Success: false,
    },
    subCategoryPost: {
      Error: false,
      Loading: false,
      Success: false,
    },
    categoryDelete: {
      Error: false,
      Loading: false,
      Success: false,
    },
    subCategoryDelete: {
      Error: false,
      Loading: false,
      Success: false,
    },
    categoryPut: {
      Error: false,
      Loading: false,
      Success: false,
    },
    subCategoryPut: {
      Error: false,
      Loading: false,
      Success: false,
    },
    categoryUpload: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
  },
  extraReducers: {
    // get
    [CategoriesGet.pending]: (state, action) => {
      state.categoriesGet.loading = true;
    },
    [CategoriesGet.fulfilled]: (state, action) => {
      state.categoriesGet.loading = false;
      state.categoriesGet.success = true;
      state.categoriesGet.data = action.payload;
      state.categoriesGet.error = false;
    },
    [CategoriesGet.rejected]: (state, action) => {
      state.categoriesGet.loading = false;
      state.categoriesGet.error = true;
      state.categoriesGet.success = false;
    },
    // subget
    [SubCategoriesGet.pending]: (state, action) => {
      state.subCategoriesGet.loading = true;
    },
    [SubCategoriesGet.fulfilled]: (state, action) => {
      state.subCategoriesGet.loading = false;
      state.subCategoriesGet.success = true;
      state.subCategoriesGet.data = action.payload;
      state.subCategoriesGet.error = false;
    },
    [SubCategoriesGet.rejected]: (state, action) => {
      state.subCategoriesGet.loading = false;
      state.subCategoriesGet.error = true;
      state.subCategoriesGet.success = false;
    },
    // post
    [CategoryPost.pending]: (state, action) => {
      state.categoryPost.loading = true;
    },
    [CategoryPost.fulfilled]: (state, action) => {
      state.categoryPost.loading = false;
      state.categoryPost.Success = true;
      state.categoryPost.Error = false;
    },
    [CategoryPost.rejected]: (state, action) => {
      state.categoryPost.loading = false;
      state.categoryPost.Error = true;
      state.categoryPost.Success = false;
    },
    // addSub
    [SubCategoryPost.pending]: (state, action) => {
      state.subCategoryPost.Loading = true;
    },
    [SubCategoryPost.fulfilled]: (state, action) => {
      state.subCategoryPost.Loading = false;
      state.subCategoryPost.Success = true;
      state.subCategoryPost.Error = false;
    },
    [SubCategoryPost.rejected]: (state, action) => {
      state.subCategoryPost.Loading = false;
      state.subCategoryPost.Error = true;
      state.subCategoryPost.Success = false;
    },
    // delete
    [CategoryDelete.pending]: (state, action) => {
      state.categoryDelete.loadingDelete = true;
    },
    [CategoryDelete.fulfilled]: (state, action) => {
      state.categoryDelete.loading = false;
      state.categoryDelete.Success = true;
      state.categoryDelete.Error = false;
    },
    [CategoryDelete.rejected]: (state, action) => {
      state.categoryDelete.loading = false;
      state.categoryDelete.Error = true;
      state.categoryDelete.Success = false;
    },
    // subdelete
    [SubCategoryDelete.pending]: (state, action) => {
      state.subCategoryDelete.loadingDelete = true;
    },
    [SubCategoryDelete.fulfilled]: (state, action) => {
      state.subCategoryDelete.loading = false;
      state.subCategoryDelete.Success = true;
      state.subCategoryDelete.Error = false;
    },
    [SubCategoryDelete.rejected]: (state, action) => {
      state.subCategoryDelete.loading = false;
      state.subCategoryDelete.Error = true;
      state.subCategoryDelete.Success = false;
    },
    // put
    [CategoryPut.pending]: (state, action) => {
      state.categoryPut.loading = true;
    },
    [CategoryPut.fulfilled]: (state, action) => {
      state.categoryPut.Error = false;
      state.categoryPut.Success = true;
      state.categoryPut.Loading = false;
    },
    [CategoryPut.rejected]: (state, action) => {
      state.categoryPut.Error = true;
      state.categoryPut.Success = false;
      state.categoryPut.Loading = false;
    },
    // subput
    [SubCategoryPut.pending]: (state, action) => {
      state.subCategoryPut.loading = true;
    },
    [SubCategoryPut.fulfilled]: (state, action) => {
      state.subCategoryPut.Error = false;
      state.subCategoryPut.Success = true;
      state.subCategoryPut.Loading = false;
    },
    [SubCategoryPut.rejected]: (state, action) => {
      state.subCategoryPut.Error = true;
      state.subCategoryPut.Success = false;
      state.subCategoryPut.Loading = false;
    },
    [CategoryUpload.pending]: (state, action) => {
      state.categoryUpload.Loading = true;
    },
    [CategoryUpload.fulfilled]: (state, action) => {
      state.categoryUpload.Error = false;
      state.categoryUpload.Success = true;
      state.categoryUpload.Loading = false;
      state.categoryUpload.data = action.payload;
    },
    [CategoryUpload.rejected]: (state, action) => {
      state.categoryUpload.Error = true;
      state.categoryUpload.Success = false;
      state.categoryUpload.Loading = false;
    },
  },
});

export const {} = Categorylice.actions;
export default Categorylice.reducer;
