import { useDispatch } from "react-redux";
import RouterComponent from "./router";
import { GlobalStyle } from "./style-App";
import { useEffect } from "react";
import { AdminGet } from "./redux/admin";
function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(AdminGet());
  }, []);
  return (
    <>
      <GlobalStyle />
      <RouterComponent />
    </>
  );
}

export default App;
