import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/api";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export const AdminPost = createAsyncThunk("admin/Add", async (body) => {
  return await axios.post(`${API_URL}/postadmin`, body).then((res) => res);
});

export const AdminGet = createAsyncThunk("admin/Get", async () => {
  return axios.get(`${API_URL}/admins`).then((response) => response.data);
});

export const AdminDelete = createAsyncThunk("admin/Delete", async (id) => {
  return await axios
    .delete(`${API_URL}/admins/${id}`, {
      headers: { token: cookies.get("token") },
    })
    .then((res) => res);
});

export const AdminUpload = createAsyncThunk("Product/upload", async (e) => {
  const formData = new FormData();
  formData.append("file", e.target.files[0]);
  formData.append("upload_preset", "vbxugvuq");
  try {
    return await axios
      .post("https://api.cloudinary.com/v1_1/dxv63ahxx/upload", formData)
      .then((response) => response?.data.secure_url);
  } catch (error) {
    return error;
  }
});
const AdminSlice = createSlice({
  name: "admin",
  initialState: {
    adminGet: {
      loading: false,
      data: [],
      error: false,
      success: false,
    },
    adminPost: {
      Success: false,
      Error: false,
      loading: false,
    },
    adminDelete: {
      Success: false,
      Error: false,
      loading: false,
    },
    adminUpload: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
  },
  extraReducers: {
    // get
    [AdminGet.pending]: (state, action) => {
      state.adminGet.loading = true;
    },
    [AdminGet.fulfilled]: (state, action) => {
      state.adminGet.loading = false;
      state.adminGet.success = true;
      state.adminGet.data = action.payload;
      state.adminGet.error = false;
    },
    [AdminGet.rejected]: (state, action) => {
      state.adminGet.loading = false;
      state.adminGet.error = true;
      state.adminGet.success = false;
    },
    // add
    [AdminPost.pending]: (state, action) => {
      state.adminPost.loading = true;
    },
    [AdminPost.fulfilled]: (state, action) => {
      state.adminPost.loading = false;
      state.adminPost.Success = true;
      state.adminPost.Error = false;
    },
    [AdminPost.rejected]: (state, action) => {
      state.adminPost.loading = false;
      state.adminPost.Error = true;
      state.adminPost.Success = false;
    },
    // delete
    [AdminDelete.pending]: (state, action) => {
      state.adminDelete.loadingDelete = true;
    },
    [AdminDelete.fulfilled]: (state, action) => {
      state.adminDelete.loading = false;
      state.adminDelete.Success = true;
      state.adminDelete.Error = false;
    },
    [AdminDelete.rejected]: (state, action) => {
      state.adminDelete.loading = false;
      state.adminDelete.Error = true;
      state.adminDelete.Success = false;
    },
    //upload
    [AdminUpload.pending]: (state, action) => {
      state.adminUpload.Loading = true;
    },
    [AdminUpload.fulfilled]: (state, action) => {
      state.adminUpload.Error = false;
      state.adminUpload.Success = true;
      state.adminUpload.Loading = false;
      state.adminUpload.data = action.payload;
    },
    [AdminUpload.rejected]: (state, action) => {
      state.adminUpload.Error = true;
      state.adminUpload.Success = false;
      state.adminUpload.Loading = false;
    },
  },
});

export const {} = AdminSlice.actions;
export default AdminSlice.reducer;
