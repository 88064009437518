import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/api";

export const PostComments = createAsyncThunk("Comments/post", async (body) => {
  return await axios
    .post(`${API_URL}/comments`, body)
    .then((res) => console.log(res));
});
export const GetComments = createAsyncThunk("Comments/get", async () => {
  return await axios
    .get(`${API_URL}/comments`)
    .then((response) => response.data);
});
export const GetIdComments = createAsyncThunk(
  "CommentsId/get",
  async (body) => {
    return await axios
      .get(`${API_URL}/comments/${body}`)
      .then((response) => response.data);
  }
);

export const DeleteComments = createAsyncThunk(
  "Comments/delete",
  async (id) => {
    return await axios
      .delete(`${API_URL}/comments/${id}`)
      .then((response) => response.data);
  }
);
export const PutComments = createAsyncThunk(
  "Comments/put",
  async ({ body, id }) => {
    return await axios
      .put(`${API_URL}/comments/${id}`, body)
      .then((response) => console.log(response.data));
  }
);
const Commentslice = createSlice({
  name: "Comments",
  initialState: {
    CommentsGet: {
      loading: false,
      data: [],
      error: false,
      success: false,
    },
    CommentsGetId: {
      loading: false,
      data: [],
      error: false,
      success: false,
    },
    CommentsPost: {
      Success: false,
      Error: false,
      loading: false,
    },
    CommentsDelete: {
      Success: false,
      Error: false,
      loading: false,
    },
    CommentsPut: {
      Error: false,
      Loading: false,
      Success: false,
    },
  },
  extraReducers: {
    // get
    [GetComments.pending]: (state, action) => {
      state.CommentsGet.loading = true;
    },
    [GetComments.fulfilled]: (state, action) => {
      state.CommentsGet.loading = false;
      state.CommentsGet.success = true;
      state.CommentsGet.data = action.payload;
      state.CommentsGet.error = false;
    },
    [GetComments.rejected]: (state, action) => {
      state.CommentsGet.loading = false;
      state.CommentsGet.error = true;
      state.CommentsGet.success = false;
    },
    // get id
    [GetIdComments.pending]: (state, action) => {
      state.CommentsGetId.loading = true;
    },
    [GetIdComments.fulfilled]: (state, action) => {
      state.CommentsGetId.loading = false;
      state.CommentsGetId.success = true;
      state.CommentsGetId.data = action.payload;
      state.CommentsGetId.error = false;
    },
    [GetIdComments.rejected]: (state, action) => {
      state.CommentsGetId.loading = false;
      state.CommentsGetId.error = true;
      state.CommentsGetId.success = false;
    },
    // add
    [PostComments.pending]: (state, action) => {
      state.CommentsPost.loading = true;
    },
    [PostComments.fulfilled]: (state, action) => {
      state.CommentsPost.loading = false;
      state.CommentsPost.Success = true;
      state.CommentsPost.Error = false;
    },
    [PostComments.rejected]: (state, action) => {
      state.CommentsPost.loading = false;
      state.CommentsPost.Error = true;
      state.CommentsPost.Success = false;
    },
    // delete
    [DeleteComments.pending]: (state, action) => {
      state.CommentsDelete.loadingDelete = true;
    },
    [DeleteComments.fulfilled]: (state, action) => {
      state.CommentsDelete.loading = false;
      state.CommentsDelete.Success = true;
      state.CommentsDelete.Error = false;
    },
    [DeleteComments.rejected]: (state, action) => {
      state.CommentsDelete.loading = false;
      state.CommentsDelete.Error = true;
      state.CommentsDelete.Success = false;
    },
    // put
    [PutComments.pending]: (state, action) => {
      state.CommentsPut.loading = true;
    },
    [PutComments.fulfilled]: (state, action) => {
      state.CommentsPut.Error = false;
      state.CommentsPut.Success = true;
      state.CommentsPut.Loading = false;
    },
    [PutComments.rejected]: (state, action) => {
      state.CommentsPut.Error = true;
      state.CommentsPut.Success = false;
      state.CommentsPut.Loading = false;
    },
  },
});

export const {} = Commentslice.actions;
export default Commentslice.reducer;
