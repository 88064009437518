import { configureStore } from "@reduxjs/toolkit";
import AuthSlice from "./login/authSlice";
import AdminSlice from "./admin/index";
import ProductSlice from "./products/index";
import Brandslice from "./brands/index";
import Categorylice from "./category/index";
import BannerCategorylice from "./bannercategory/index";
import Bannerlice from "./banner/index";
import Orderslice from "./orders/index";
import Commentslice from "./comments/index";
import Userlice from "./users/index";

export const store = configureStore({
  reducer: {
    auth: AuthSlice,
    admin: AdminSlice,
    product: ProductSlice,
    brand: Brandslice,
    category: Categorylice,
    bannercategory: BannerCategorylice,
    banner: Bannerlice,
    orders: Orderslice,
    comments: Commentslice,
    users: Userlice,
  },
});
