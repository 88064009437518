import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/api";

export const GetUser = createAsyncThunk("User/get", async () => {
  return await axios.get(`${API_URL}/users`).then((response) => response.data);
});
const Userlice = createSlice({
  name: "User",
  initialState: {
    UserGet: {
      loading: false,
      data: [],
      error: false,
      success: false,
    },
  },
  extraReducers: {
    // get
    [GetUser.pending]: (state, action) => {
      state.UserGet.loading = true;
    },
    [GetUser.fulfilled]: (state, action) => {
      state.UserGet.loading = false;
      state.UserGet.success = true;
      state.UserGet.data = action.payload;
      state.UserGet.error = false;
    },
    [GetUser.rejected]: (state, action) => {
      state.UserGet.loading = false;
      state.UserGet.error = true;
      state.UserGet.success = false;
    },
  },
});

export const {} = Userlice.actions;
export default Userlice.reducer;
