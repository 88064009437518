import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/api";

export const PostBanner = createAsyncThunk("Banner/post", async (body) => {
  return await axios.post(`${API_URL}/banner`, body).then((res) => res);
});
export const GetBanner = createAsyncThunk("Banner/get", async () => {
  return await axios.get(`${API_URL}/banner`).then((response) => response.data);
});

export const DeleteBanner = createAsyncThunk("Banner/delete", async (id) => {
  return await axios
    .delete(`${API_URL}/banner/${id}`)
    .then((response) => response.data);
});
export const PutBanner = createAsyncThunk(
  "Banner/put",
  async ({ body, id }) => {
    return await axios
      .put(`${API_URL}/banner/${id}`, body)
      .then((response) => console.log(response.data));
  }
);
export const UploadBanner = createAsyncThunk("Banner/upload", async (e) => {
  const formData = new FormData();
  formData.append("file", e.target.files[0]);
  formData.append("upload_preset", "vbxugvuq");
  try {
    return await axios
      .post("https://api.cloudinary.com/v1_1/dxv63ahxx/upload", formData)
      .then((response) => response?.data.secure_url);
  } catch (error) {
    return error;
  }
});

export const UploadPutBanner = createAsyncThunk(
  "Banner/uploadPut",
  async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "vbxugvuq");
    try {
      return await axios
        .post("https://api.cloudinary.com/v1_1/dxv63ahxx/upload", formData)
        .then((response) => response?.data.secure_url);
    } catch (error) {
      return error;
    }
  }
);
const Bannerlice = createSlice({
  name: "Banner",
  initialState: {
    BannerGet: {
      loading: false,
      data: [],
      error: false,
      success: false,
    },
    BannerPost: {
      Success: false,
      Error: false,
      loading: false,
    },
    BannerDelete: {
      Success: false,
      Error: false,
      loading: false,
    },
    BannerPut: {
      Error: false,
      Loading: false,
      Success: false,
    },
    uploadBanner: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
    uploadPutBanner: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
  },
  extraReducers: {
    // get
    [GetBanner.pending]: (state, action) => {
      state.BannerGet.loading = true;
    },
    [GetBanner.fulfilled]: (state, action) => {
      state.BannerGet.loading = false;
      state.BannerGet.success = true;
      state.BannerGet.data = action.payload;
      state.BannerGet.error = false;
    },
    [GetBanner.rejected]: (state, action) => {
      state.BannerGet.loading = false;
      state.BannerGet.error = true;
      state.BannerGet.success = false;
    },
    // add
    [PostBanner.pending]: (state, action) => {
      state.BannerPost.loading = true;
    },
    [PostBanner.fulfilled]: (state, action) => {
      state.BannerPost.loading = false;
      state.BannerPost.Success = true;
      state.BannerPost.Error = false;
    },
    [PostBanner.rejected]: (state, action) => {
      state.BannerPost.loading = false;
      state.BannerPost.Error = true;
      state.BannerPost.Success = false;
    },
    // delete
    [DeleteBanner.pending]: (state, action) => {
      state.BannerDelete.loadingDelete = true;
    },
    [DeleteBanner.fulfilled]: (state, action) => {
      state.BannerDelete.loading = false;
      state.BannerDelete.Success = true;
      state.BannerDelete.Error = false;
    },
    [DeleteBanner.rejected]: (state, action) => {
      state.BannerDelete.loading = false;
      state.BannerDelete.Error = true;
      state.BannerDelete.Success = false;
    },
    // put
    [PutBanner.pending]: (state, action) => {
      state.BannerPut.loading = true;
    },
    [PutBanner.fulfilled]: (state, action) => {
      state.BannerPut.Error = false;
      state.BannerPut.Success = true;
      state.BannerPut.Loading = false;
    },
    [PutBanner.rejected]: (state, action) => {
      state.BannerPut.Error = true;
      state.BannerPut.Success = false;
      state.BannerPut.Loading = false;
    },
    //upload

    [UploadBanner.pending]: (state, action) => {
      state.uploadBanner.Loading = true;
    },
    [UploadBanner.fulfilled]: (state, action) => {
      state.uploadBanner.Error = false;
      state.uploadBanner.Success = true;
      state.uploadBanner.Loading = false;
      state.uploadBanner.data = action.payload;
    },
    [UploadBanner.rejected]: (state, action) => {
      state.uploadBanner.Error = true;
      state.uploadBanner.Success = false;
      state.uploadBanner.Loading = false;
    },
    //uploadPut
    [UploadPutBanner.pending]: (state, action) => {
      state.uploadPutBanner.Loading = true;
    },
    [UploadPutBanner.fulfilled]: (state, action) => {
      state.uploadPutBanner.Error = false;
      state.uploadPutBanner.Success = true;
      state.uploadPutBanner.Loading = false;
      state.uploadPutBanner.data = action.payload;
    },
    [UploadPutBanner.rejected]: (state, action) => {
      state.uploadPutBanner.Error = true;
      state.uploadPutBanner.Success = false;
      state.uploadPutBanner.Loading = false;
    },
  },
});

export const {} = Bannerlice.actions;
export default Bannerlice.reducer;
