import { lazy } from "react";
import Cookies from "universal-cookie";
const Login = lazy(() => import("../pages/login/index"));
const AddAdmin = lazy(() => import("../pages/Admin/index"));
const Products = lazy(() => import("../pages/Products/index"));
const Brands = lazy(() => import("../pages/Brands/index"));
const Category = lazy(() => import("../pages/Category/index"));
const Banner = lazy(() => import("../pages/Banner/index"));
const Orders = lazy(() => import("../pages/Orders/index"));
const GottenOrders = lazy(() => import("../pages/GottenOrders/index"));
const Users = lazy(() => import("../pages/Users/index"));
const Comments = lazy(() => import("../pages/Comments/index"));
const cookie = new Cookies();
const token = cookie.get("token");

export const RouterData = [
  {
    id: 1,
    path: "/",
    component: <Login />,
  },
  {
    id: 2,
    path: "/adminadd",
    component: cookie.get("token") ? <AddAdmin /> : null,
  },
  {
    id: 3,
    path: "/products",
    component: cookie.get("token") ? <Products /> : null,
  },
  {
    id: 4,
    path: "/brands",
    component: cookie.get("token") ? <Brands /> : null,
  },
  {
    id: 5,
    path: "/category",
    component: cookie.get("token") ? <Category /> : null,
  },
  {
    id: 6,
    path: "/banner",
    component: cookie.get("token") ? <Banner /> : null,
  },
  {
    id: 7,
    path: "/neworders",
    component: cookie.get("token") ? <Orders /> : null,
  },
  {
    id: 8,
    path: "/gottenorders",
    component: cookie.get("token") ? <GottenOrders /> : null,
  },
  {
    id: 9,
    path: "/users",
    component: cookie.get("token") ? <Users /> : null,
  },
  {
    id: 10,
    path: "/comments",
    component: cookie.get("token") ? <Comments /> : null,
  },
];
