import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/api";

export const BrandPost = createAsyncThunk("/post", async (body) => {
  return await axios.post(`${API_URL}/brands`, body).then((res) => res);
});
export const BrandsGet = createAsyncThunk("Brands/get", async () => {
  return await axios.get(`${API_URL}/brands`).then((response) => response.data);
});
export const BrandsSearch = createAsyncThunk(
  "BrandsSearch/get",
  async ({ value }) => {
    return await axios
      .get(`${API_URL}/brandssearch?search=${value}`)
      .then((response) => response.data);
  }
);

export const BrandDelete = createAsyncThunk("Brands/delete", async (id) => {
  return await axios
    .delete(`${API_URL}/brands/${id}`)
    .then((response) => response.data);
});
export const BrandPut = createAsyncThunk("Brands/put", async ({ body, id }) => {
  return await axios
    .put(`${API_URL}/brands/${id}`, body)
    .then((response) => console.log(response.data));
});
export const BrandUpload = createAsyncThunk("Brands/upload", async (e) => {
  const formData = new FormData();
  formData.append("file", e.target.files[0]);
  formData.append("upload_preset", "vbxugvuq");
  try {
    return await axios
      .post("https://api.cloudinary.com/v1_1/dxv63ahxx/upload", formData)
      .then((response) => response?.data.secure_url);
  } catch (error) {
    return error;
  }
});

const Brandslice = createSlice({
  name: "Brands",
  initialState: {
    brandsGet: {
      loading: false,
      data: [],
      error: false,
      success: false,
    },
    brandsSearch: {
      loading: false,
      data: [],
      error: false,
      success: false,
    },
    brandPost: {
      Success: false,
      Error: false,
      loading: false,
    },
    brandDelete: {
      Success: false,
      Error: false,
      loading: false,
    },
    brandPut: {
      Error: false,
      Loading: false,
      Success: false,
    },
    brandUpload: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
  },
  extraReducers: {
    // get
    [BrandsGet.pending]: (state, action) => {
      state.brandsGet.loading = true;
    },
    [BrandsGet.fulfilled]: (state, action) => {
      state.brandsGet.loading = false;
      state.brandsGet.success = true;
      state.brandsGet.data = action.payload;
      state.brandsGet.error = false;
    },
    [BrandsGet.rejected]: (state, action) => {
      state.brandsGet.loading = false;
      state.brandsGet.error = true;
      state.brandsGet.success = false;
    },

    [BrandsSearch.pending]: (state, action) => {
      state.brandsSearch.loading = true;
    },
    [BrandsSearch.fulfilled]: (state, action) => {
      state.brandsSearch.loading = false;
      state.brandsSearch.success = true;
      state.brandsSearch.data = action.payload;
      state.brandsSearch.error = false;
    },
    [BrandsSearch.rejected]: (state, action) => {
      state.brandsSearch.loading = false;
      state.brandsSearch.error = true;
      state.brandsSearch.success = false;
    },

    // add
    [BrandPost.pending]: (state, action) => {
      state.brandPost.loading = true;
    },
    [BrandPost.fulfilled]: (state, action) => {
      state.brandPost.loading = false;
      state.brandPost.Success = true;
      state.brandPost.Error = false;
    },
    [BrandPost.rejected]: (state, action) => {
      state.brandPost.loading = false;
      state.brandPost.Error = true;
      state.brandPost.Success = false;
    },
    // delete
    [BrandDelete.pending]: (state, action) => {
      state.brandDelete.loadingDelete = true;
    },
    [BrandDelete.fulfilled]: (state, action) => {
      state.brandDelete.loading = false;
      state.brandDelete.Success = true;
      state.brandDelete.Error = false;
    },
    [BrandDelete.rejected]: (state, action) => {
      state.brandDelete.loading = false;
      state.brandDelete.Error = true;
      state.brandDelete.Success = false;
    },
    // put
    [BrandPut.pending]: (state, action) => {
      state.brandPut.loading = true;
    },
    [BrandPut.fulfilled]: (state, action) => {
      state.brandPut.Error = false;
      state.brandPut.Success = true;
      state.brandPut.Loading = false;
    },
    [BrandPut.rejected]: (state, action) => {
      state.brandPut.Error = true;
      state.brandPut.Success = false;
      state.brandPut.Loading = false;
    },
    //upload

    [BrandUpload.pending]: (state, action) => {
      state.brandUpload.Loading = true;
    },
    [BrandUpload.fulfilled]: (state, action) => {
      state.brandUpload.Error = false;
      state.brandUpload.Success = true;
      state.brandUpload.Loading = false;
      state.brandUpload.data = action.payload;
    },
    [BrandUpload.rejected]: (state, action) => {
      state.brandUpload.Error = true;
      state.brandUpload.Success = false;
      state.brandUpload.Loading = false;
    },
  },
});

export const {} = Brandslice.actions;
export default Brandslice.reducer;
