import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/api";

export const GetOrders = createAsyncThunk("Orders/get", async () => {
  return await axios.get(`${API_URL}/orders`).then((response) => response.data);
});
export const GetIdOrders = createAsyncThunk("OrdersId/get", async (body) => {
  return await axios
    .get(`${API_URL}/orders/${body}`)
    .then((response) => response.data);
});
export const GetFilterOrders = createAsyncThunk(
  "OrdersFilter/get",
  async ({ status, payment, sell, date }) => {
    return await axios
      .get(
        `${API_URL}/orders?status=${status}&typePayment=${payment}&typeSell=${sell}&date=${date}`
      )
      .then((response) => response.data);
  }
);
export const GetSearchOrders = createAsyncThunk(
  "OrdersSearch/get",
  async ({ value }) => {
    return await axios
      .get(`${API_URL}/searchorder?search=${value}`)
      .then((response) => response.data);
  }
);

export const PutOrders = createAsyncThunk(
  "Orders/put",
  async ({ body, id }) => {
    return await axios
      .put(`${API_URL}/orders/${id}`, body)
      .then((response) => console.log(response.data));
  }
);
const Orderslice = createSlice({
  name: "Orders",
  initialState: {
    OrdersGet: {
      loading: false,
      data: [],
      error: false,
      success: false,
    },
    OrdersGetId: {
      loading: false,
      data: [],
      error: false,
      success: false,
    },
    OrdersGetFilter: {
      loading: false,
      data: [],
      error: false,
      success: false,
    },
    OrdersGetSearch: {
      loading: false,
      data: [],
      error: false,
      success: false,
    },
    OrdersPut: {
      Error: false,
      Loading: false,
      Success: false,
    },
  },
  extraReducers: {
    // get
    [GetOrders.pending]: (state, action) => {
      state.OrdersGet.loading = true;
    },
    [GetOrders.fulfilled]: (state, action) => {
      state.OrdersGet.loading = false;
      state.OrdersGet.success = true;
      state.OrdersGet.data = action.payload;
      state.OrdersGet.error = false;
    },
    [GetOrders.rejected]: (state, action) => {
      state.OrdersGet.loading = false;
      state.OrdersGet.error = true;
      state.OrdersGet.success = false;
    },
    [GetIdOrders.pending]: (state, action) => {
      state.OrdersGetId.loading = true;
    },
    [GetIdOrders.fulfilled]: (state, action) => {
      state.OrdersGetId.loading = false;
      state.OrdersGetId.success = true;
      state.OrdersGetId.data = action.payload;
      state.OrdersGetId.error = false;
    },
    [GetIdOrders.rejected]: (state, action) => {
      state.OrdersGetId.loading = false;
      state.OrdersGetId.error = true;
      state.OrdersGetId.success = false;
    },
    [GetFilterOrders.pending]: (state, action) => {
      state.OrdersGetFilter.loading = true;
    },
    [GetFilterOrders.fulfilled]: (state, action) => {
      state.OrdersGetFilter.loading = false;
      state.OrdersGetFilter.success = true;
      state.OrdersGetFilter.data = action.payload;
      state.OrdersGetFilter.error = false;
    },
    [GetFilterOrders.rejected]: (state, action) => {
      state.OrdersGetFilter.loading = false;
      state.OrdersGetFilter.error = true;
      state.OrdersGetFilter.success = false;
    },
    [GetSearchOrders.pending]: (state, action) => {
      state.OrdersGetSearch.loading = true;
    },
    [GetSearchOrders.fulfilled]: (state, action) => {
      state.OrdersGetSearch.loading = false;
      state.OrdersGetSearch.success = true;
      state.OrdersGetSearch.data = action.payload;
      state.OrdersGetSearch.error = false;
    },
    [GetSearchOrders.rejected]: (state, action) => {
      state.OrdersGetSearch.loading = false;
      state.OrdersGetSearch.error = true;
      state.OrdersGetSearch.success = false;
    },
    // put
    [PutOrders.pending]: (state, action) => {
      state.OrdersPut.loading = true;
    },
    [PutOrders.fulfilled]: (state, action) => {
      state.OrdersPut.Error = false;
      state.OrdersPut.Success = true;
      state.OrdersPut.Loading = false;
    },
    [PutOrders.rejected]: (state, action) => {
      state.OrdersPut.Error = true;
      state.OrdersPut.Success = false;
      state.OrdersPut.Loading = false;
    },
  },
});

export const {} = Orderslice.actions;
export default Orderslice.reducer;
