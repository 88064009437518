import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/api";

export const PostBannerCategory = createAsyncThunk(
  "BannerCategory/post",
  async (body) => {
    return await axios
      .post(`${API_URL}/banner_category`, body)
      .then((res) => res);
  }
);
export const GetBannerCategory = createAsyncThunk(
  "BannerCategory/get",
  async () => {
    return await axios
      .get(`${API_URL}/banner_category`)
      .then((response) => response.data);
  }
);

export const DeleteBannerCategory = createAsyncThunk(
  "BannerCategory/delete",
  async (id) => {
    return await axios
      .delete(`${API_URL}/banner_category/${id}`)
      .then((response) => response.data);
  }
);
export const PutBannerCategory = createAsyncThunk(
  "BannerCategory/put",
  async ({ body, id }) => {
    return await axios
      .put(`${API_URL}/banner_category/${id}`, body)
      .then((response) => console.log(response.data));
  }
);
const BannerCategorylice = createSlice({
  name: "BannerCategory",
  initialState: {
    BannerCategoryGet: {
      loading: false,
      data: [],
      error: false,
      success: false,
    },
    BannerCategoryPost: {
      Success: false,
      Error: false,
      loading: false,
    },
    BannerCategoryDelete: {
      Success: false,
      Error: false,
      loading: false,
    },
    BannerCategoryPut: {
      Error: false,
      Loading: false,
      Success: false,
    },
  },
  extraReducers: {
    // get
    [GetBannerCategory.pending]: (state, action) => {
      state.BannerCategoryGet.loading = true;
    },
    [GetBannerCategory.fulfilled]: (state, action) => {
      state.BannerCategoryGet.loading = false;
      state.BannerCategoryGet.success = true;
      state.BannerCategoryGet.data = action.payload;
      state.BannerCategoryGet.error = false;
    },
    [GetBannerCategory.rejected]: (state, action) => {
      state.BannerCategoryGet.loading = false;
      state.BannerCategoryGet.error = true;
      state.BannerCategoryGet.success = false;
    },
    // add
    [PostBannerCategory.pending]: (state, action) => {
      state.BannerCategoryPost.loading = true;
    },
    [PostBannerCategory.fulfilled]: (state, action) => {
      state.BannerCategoryPost.loading = false;
      state.BannerCategoryPost.Success = true;
      state.BannerCategoryPost.Error = false;
    },
    [PostBannerCategory.rejected]: (state, action) => {
      state.BannerCategoryPost.loading = false;
      state.BannerCategoryPost.Error = true;
      state.BannerCategoryPost.Success = false;
    },
    // delete
    [DeleteBannerCategory.pending]: (state, action) => {
      state.BannerCategoryDelete.loadingDelete = true;
    },
    [DeleteBannerCategory.fulfilled]: (state, action) => {
      state.BannerCategoryDelete.loading = false;
      state.BannerCategoryDelete.Success = true;
      state.BannerCategoryDelete.Error = false;
    },
    [DeleteBannerCategory.rejected]: (state, action) => {
      state.BannerCategoryDelete.loading = false;
      state.BannerCategoryDelete.Error = true;
      state.BannerCategoryDelete.Success = false;
    },
    // put
    [PutBannerCategory.pending]: (state, action) => {
      state.BannerCategoryPut.loading = true;
    },
    [PutBannerCategory.fulfilled]: (state, action) => {
      state.BannerCategoryPut.Error = false;
      state.BannerCategoryPut.Success = true;
      state.BannerCategoryPut.Loading = false;
    },
    [PutBannerCategory.rejected]: (state, action) => {
      state.BannerCategoryPut.Error = true;
      state.BannerCategoryPut.Success = false;
      state.BannerCategoryPut.Loading = false;
    },
  },
});

export const {} = BannerCategorylice.actions;
export default BannerCategorylice.reducer;
